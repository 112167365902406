<template>
    <div class="w3c-payment-method-overlay" @click.self="closeW3CDialog()">
        <Loader :content="$t('loading')" v-if="showLoader"></Loader>
        <div :class="{'w3c-payment-method': true, 'show': showW3CMethod}">
            <div class="w3c-payment-header">
                <div class="icon" :style="{backgroundImage: `url(${require('@/assets/img/wl-symbol-logo.png')})`}"></div>
                <div class="infos">
                    <span class="title">PWA</span>
                    <span class="url"><i class="icon-lock-fill"></i>https://wldd-demonstrations-bo.pubqlf.co.as8677.net/</span>
                </div>
                <span class="icon-close" @click="closeW3CDialog()"></span>
            </div>
            <div class="w3c-payment-body">
                <div class="w3c-payment-component">
                    <div class="infos">
                        <span class="title">Order summary</span>
                        <span class="">Montant total</span>
                    </div>
                    <div class="amount">{{totalAmount}} €</div>
                </div>
                <div class="w3c-payment-component">
                    <div class="infos">
                        <span class="title">Payment Method</span>
                        <span class="">Visa &#8226;&#8226;&#8226;&#8226;0021, Name NAME</span>
                    </div>
                    <div class="logo" :style="{backgroundImage: `url(${require('@/assets/img/visa.png')})`}"></div>
                </div>
                <div class="w3c-payment-component">
                    <div class="infos">
                        <span class="title">Contact info</span>
                        <span class="">+33 6 00 00 00 00</span>
                    </div>
                </div>
            </div>
            <div class="w3c-payment-footer">
                <div class="logo" :style="{backgroundImage: `url(${require('@/assets/img/google_chrome_logo.png')})`}"></div>
                <div class="buttons">
                    <button>EDIT</button>
                    <button class="confirm" @click="checkout()">PAY</button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>

import Loader from "@/components/Common/Loader";
export default {
    props:["totalAmount"],
    components: {Loader},
    data() {
        return {
            showW3CMethod: false,
            showLoader: false
        }
    },
    mounted(){
        setTimeout(() => {
            this.showW3CMethod = true
        }, 500);
    }, 
    methods:{
        closeW3CDialog(){
            this.showW3CMethod = false;
            setTimeout(() => {
                this.$emit("close")
            }, 500);
        }, 
        checkout(){
            this.showLoader = true
            setTimeout(() => {
                this.$router.push('/paymentdone')
            }, 1000);
        }
    }
}
</script>
<style>
    .w3c-payment-method{
        transition: all .5s ease-out;
        position: absolute;
        bottom: 0;
        background: white;
        width: 100%;
        padding: 8px 16px;
        border-top-right-radius: 10px;
        border-top-left-radius: 10px;
        box-shadow: -1px -7px 8px #0000001f;
        height: 0;
        opacity: 0;
    }
    .w3c-payment-method.show{
        height: 435px;
        opacity: 1;
    }
    .w3c-payment-header{
        display: flex;
        align-items: center;
        margin-bottom: 16px;
        justify-content: space-between;
    }
    .w3c-payment-header .icon-close{
        padding: 8px;
    }
    .w3c-payment-header .icon{
        background-size: contain;
        background-position: center;
        width: 48px;
        height: 48px;
        background-repeat: no-repeat;
        margin: 0 8px 0 0;
    }
    .w3c-payment-component .logo, .w3c-payment-footer .logo{
        background-size: contain;
        background-position: center;
        width: 48px;
        height: 48px;
        background-repeat: no-repeat;
        margin: 0 8px 0 0;
    }
    .w3c-payment-footer{
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .w3c-payment-component{
        padding: 8px 0 16px 0;
        border-bottom: solid 1px #e1e1e1;
        justify-content: space-between;
    }
    .w3c-payment-component .amount{
        font-weight: bold;
    }
    .w3c-payment-header .icon-question{
        margin-left: auto;
    }
    .w3c-payment-header .url{
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 90%;
    }
    .w3c-payment-header .infos, .w3c-payment-component .infos{
        display: flex;
        flex-direction: column;
        width: 80%;
    }
    .w3c-payment-header .infos .title{
        font-weight: bold;
    }
    .w3c-payment-component .title{
        font-size: 0.8rem;
        color: #4285f4;
    }
    .w3c-payment-component{
        display: flex;
        align-items: center;
    }
    .w3c-payment-footer .buttons{
        display: flex;
        justify-content: flex-end;
        margin: 16px 0;
    }
    .w3c-payment-footer .buttons button{
        color: #4285f4;
        margin: 0 8px;
        padding: 8px 16px;
        border: none;
        background: #f9f9f9;
        font-size: .8rem;
    }
    .w3c-payment-footer .buttons button.confirm{
        background: #4285f4;
        color: white;
    }
    .w3c-payment-method-overlay{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100vh;
        background: #00000075;
        z-index: 100;
        overflow: hidden;
    }
</style>