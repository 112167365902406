<template>
    <div class="google-play">
        <google-pay-button
            environment="TEST"
            :button-type="buttonType"
            :button-color="buttonColor"
            :button-locale="$i18n.locale"
            :existing-payment-method-required="existingPaymentMethodRequired"
            :paymentRequest.prop="{
              apiVersion: paymentRequest.apiVersion,
              apiVersionMinor: paymentRequest.apiVersionMinor,
              allowedPaymentMethods: paymentRequest.allowedPaymentMethods,
              merchantInfo: paymentRequest.merchantInfo,
              transactionInfo: {
                totalPriceStatus: 'FINAL',
                totalPriceLabel: 'Total',
                totalPrice: amount,
                currencyCode: 'EUR',
                countryCode: $t('countryCode')
              },
            }"
            @readytopaychange="onReadyToPayChange"
            @loadpaymentdata="onLoadPaymentData"
            :errorCallback.prop="onError"
            :cancelCallback.prop="onCancel"
            :clickCallback.prop="onClick"
            :onPaymentAuthorizedCallback.prop="onPaymentDataAuthorized"
          ></google-pay-button>
    </div>
</template>
<script>
import '@google-pay/button-element';

export default {
  name: 'GooglePay',
  props: {
      amount: {
          type: String,
          required: true
      }
  },
  data: () => ({
    existingPaymentMethodRequired: false,
    buttonColor: 'default',
    buttonType: 'buy',
    paymentRequest: {
      apiVersion: 2,
      apiVersionMinor: 0,
      allowedPaymentMethods: [
        {
          type: 'CARD',
          parameters: {
            allowedAuthMethods: ['PAN_ONLY', 'CRYPTOGRAM_3DS'],
            allowedCardNetworks: ['MASTERCARD', 'VISA'],
          },
          tokenizationSpecification: {
            type: 'PAYMENT_GATEWAY',
            "parameters": {
                "gateway": "stripe",
                "stripe:version": "2018-11-08",
                "stripe:publishableKey": "pk_test_19ow8cjRvtMRxKzIVyTwOEam"
            },
          },
        },
      ],
      callbackIntents: ['PAYMENT_AUTHORIZATION'],
      merchantInfo: {
            merchantId: '0123456789',
            merchantName: 'WeShop'
        },
    },
  }),
  methods: {
    onLoadPaymentData(event){
      console.log('load payment data', event);
      this.$router.push('/paymentdone')
    },
    onError: event => {
      console.error('error', event.error);
    },
    onCancel: event => {
      console.error('cancel', event.error);
    },
    onPaymentDataAuthorized: paymentData => {
      console.log('payment authorized', paymentData);
      console.log(paymentData)
      return {
        transactionState: 'SUCCESS',
      };
    },
    onReadyToPayChange: event => {
      console.log('ready to pay change', event.detail);
    },
    onClick: () => {
      console.log('click');
    }
  },
};
</script>
