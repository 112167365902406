<template>
    <div class="footer-wrapper">
        <div class="footer-btns-wrapper">
            <div :class="{'footer-btn': true, 'translucide': basketIsEmpty || disableCheckoutButton || isOffline, 'smallTextSize': isOffline}" @click="handleCheckoutClick">
                <template v-if="!inPaymentMethods">
                    <span class="label" v-if="isOffline">{{$t("offlineCheckoutText")}}</span>
                    <span class="label" v-else-if="!basketIsEmpty">{{$t("basketFooterText")}}</span>
                    <span v-else class="label">{{$t("basketEmptyFooterText")}}</span>
                </template>
                <template v-else>
                    <span class="label">{{$t("pay")}} {{formatPrice(basket.totalAmount)}}</span>
                </template>
            </div>
        </div>
    </div>
</template>

<script>
    var basket = require('@/assets/js/Food/Basket');
    import {getLocalBasket, decreaseProductFromLocalBasket} from "@/assets/js/Food/Basket";
    import {formatPrice} from "@/assets/js/Utils";

    export default {
        name:"FooterComponent",
        props:{
            scannedProduct: Object,
            inPaymentMethods: {
                type: Boolean,
                default: false
            },
            disableCheckoutButton: {
                type: Boolean,
                default: false
            },
            disableScanBtn: {
                type: Boolean,
                default: false
            },
            isOffline:{
                type: Boolean,
                default: false
            },
            askBeforeCheckout:{
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                basket: getLocalBasket(),
                expandBasket: false,
                expandScanned: false,
                productToDelete: null
            }
        },
        methods:{
            formatPrice,
            deleteProduct(){
                decreaseProductFromLocalBasket(this.productToDelete)
                sessionStorage.setItem("DKC_food_suspect_basket", true);
                this.$parent.$parent.basketChange();
                this.basket = getLocalBasket()
                this.productToDelete = null;
            },
            toPaymentMethod(){
                basket.updateBasket(newBasket => {
                    localStorage.setItem("DKC_basket", JSON.stringify(newBasket));
                    this.$root.$emit('basketChgt');
                    this.$router.push('/paymentMethods');
                })
            },
            toBasket(){
                this.$router.push('/basket');
            },
            handleCheckoutClick(){
                if(!this.inPaymentMethods && !this.basketIsEmpty && !this.isOffline && this.askBeforeCheckout){
                    this.toBasket()
                }else if(!this.inPaymentMethods && !this.basketIsEmpty && !this.isOffline && !this.askBeforeCheckout){
                    this.toPaymentMethod()
                }else if(this.inPaymentMethods && !this.disableCheckoutButton){
                    this.$emit("goToPayment")
                }else if(this.inPaymentMethods && this.disableCheckoutButton){
                  this.$emit("goToPaymentError")
                }
            }
        },
        computed: {
            basketIsEmpty(){
                return this.basket === null || this.basket.basketProducts.length == 0;
            }
        },
        watch: {
            scannedProduct(){
                this.expandBasket = false;
                this.expandScanned = true;
                this.basket = getLocalBasket()
                setTimeout(() => this.expandScanned = false, 5000)
            }
        },

    }
</script>
